<template>
  <div
    v-if="showCanceledOrderTip"
    v-expose="{
      id: '1-8-3-66'
    }"
    v-tap="{
      id: '1-8-3-67'
    }"
    class="canceled-order-tip"
    @click="handleView"
  >
    <ImgStackGallery
      v-if="imgList.length"
      :img-list="imgList"
      :count="totalNums"
    />
    <div class="canceled-content">
      <div class="content-up">
        <span
          class="canceled-tip"
          v-html="canceledOrderInfo.canceledOrderTip"
        ></span>
      </div>
      <div
        v-if="lureInfo.type"
        class="content-down"
        :style="{
          color: lureInfo.textColor,
        }"
      >
        <img
          v-if="lureInfo.prefixIcon"
          :src="lureInfo.prefixIcon"
          width="12"
          height="12"
        />
        <span
          class="lureInfo-text"
          v-html="lureInfo.text"
        ></span>
      </div>
    </div>
    <div class="view-button">
      <span class="button-text">{{ language.SHEIN_KEY_PWA_18965 }}</span>
    </div>
    <div
      v-tap="{
        id: '1-8-3-68'
      }"
      class="close-button"
      @click.stop="onClickClose"
    >
      <sui_icon_close_16px_2
        size="16px"
        color="#959595"
      />
    </div>
  </div>
</template>
<script setup>
import { defineExpose, computed, onMounted, watch } from 'vue'
import ImgStackGallery from '../unpaidOrderTip/ImgStackGallery.vue'
import { sui_icon_close_16px_2 } from '@shein-aidc/icon-vue2'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore'

const { commit, state } = useStore()
const showCanceledOrderDraw = computed(() => state.showCanceledOrderDraw)
const showCanceledOrderTip = computed(() => state.orderState.showCanceledOrderTip)

const props = defineProps({
  canceledOrderInfo: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
})


const imgList = computed(() => {
  if (!props.canceledOrderInfo?.goodsImgList) return []
  return [...props.canceledOrderInfo.goodsImgList].splice(0, 3)
})
const totalNums = computed(() => {
  let count = props.canceledOrderInfo?.goodsNum
  if(count == 1) return 0
  if(count > 99) return '99+'
  if(count > 1) return count // 仅有1张图不展示下标
  return 0
})
const lureInfo = computed(() => {
  return props.canceledOrderInfo?.lureInfo || {}
})

const handleView = () => {
  clearTimeout(timer)
  timer = null
  commit('updateState', {
    key: 'showCanceledOrderDraw',
    value: true
  })
}
watch(() => showCanceledOrderDraw.value, (val) => {
  if (!val) {
    autoClose()
  }
})

const onClickClose = () => {
  commit('updateOrderState', { showCanceledOrderTip: false })
}

const AUTO_CLOSE_TIME = 60000
let timer
const autoClose = () => {
  timer = setTimeout(() => {
    commit('updateOrderState', { showCanceledOrderTip: false })
    clearTimeout(timer)
    timer = null
  }, AUTO_CLOSE_TIME)
}
onMounted(() => {
  autoClose()
})

defineExpose({
  imgList,
})

</script>
<style lang="less" scoped>
.canceled-order-tip {
  display: flex;
  padding: 2/75rem 24/75rem;
  height: 88/75rem;
  gap: 20/75rem;
  align-items: center;
  background: linear-gradient(90deg, #FFECE9 2.73%, #FFFAF9 100%);
}
.canceled-content {
  flex: 1;
  overflow: hidden;
}
.content-up, .content-down {
  display: flex;
  align-items: center;
  gap: 4/75rem;
  width: 100%;
}
.view-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80/75rem;
  max-width: 132/75rem;
  padding: 10/75rem 16/75rem;
  border-radius: 56/75rem;
  background: #FA6338;
  height: 48/75rem;
  .button-text {
    font-size: 11px;
    font-weight: 590;
    color: @sui_color_white;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.lureInfo-text, .canceled-tip {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
