<template>
  <div
    v-if="freeShippinpTips"
    class="free-color"
  >
    <div
      
      v-expose="analysisEvent('1-8-5-3')"
      :class="[
        'free-ship',
        showCartRights ? 'old-rights-funs' : '',
        {'free-ship_activity': showCartActivityColor}
      ]"
      @click="clickCartTip"
    >
      <div class="icon-tip-wrap">
        <slot>
          <component
            :is="!showShipSupplement ? sui_icon_selected_16px: showCartRights ? sui_icon_freeshipping_16px_color : sui_icon_shipping_16px"
            class="item-icon"
            size="16px"
            :color="showCartRights ? '#0E8C33' : '#222'"
          />
        </slot>
        <div
          v-if="freeShippinpTips"
          class="ship-content"
          :class="{'ship-content_activity': showCartActivityColor}"
        >
          <div 
            class="tips" 
            v-html="freeShippinpTips"
          >
          </div>
          <div
            v-if="shippingInfo.showCountDown && shippingInfo.countDownTime > 0"
            :class="{'float-count-down': floatCountDown}"
          >
            <ClientOnly>
              <count-down
                :is-left-time="true"
                :time-stamp="shippingInfo.countDownTime"
                :type="countDownType"
              />
            </ClientOnly>
          </div>
        </div>
      </div>
      <a
        v-if="showShipSupplement"
        :class="[
          'cart-tip__operation',
          showCartRights ? 'cart-tip__operation_new' : ''
        ]"
        tabindex="0"
        :aria-label="language.SHEIN_KEY_PWA_15091"
      >
        <span :class="{ 'free-shipping-button': showCartRights }">
          {{ language.SHEIN_KEY_PWA_15091 }}
        </span>
        <sui_icon_more_right_12px
          size="12px"
          :is-rotate="cssRight"
        />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NewFreeShip',
}
</script>
<script setup>
import { computed } from 'vue'
import { sui_icon_more_right_12px, sui_icon_selected_16px, sui_icon_freeshipping_16px_color, sui_icon_shipping_16px } from '@shein-aidc/icon-vue2'
import { daEventCenter } from 'public/src/services/eventCenterV2/index'
import CountDown from 'public/src/pages/cart_v2/components/common/countDown.vue'
import ClientOnly from 'vue-client-only'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'

const emit = defineEmits(['addOn'])

const { getters } = useStore()

const props = defineProps({
  shippingInfo: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  showCartRights: {
    type: Boolean,
    default: false
  },
  floatCountDown: {
    type: Boolean,
    default: false,
  },
  cssRight: {
    type: Boolean,
    default: false,
  },
  mallCode: {
    type: String,
    default: '',
  },
  showWay: {
    type: String,
    default: '-',
  },
  // 是否在弹窗中
  isInModal: {
    type: Boolean,
    default: false,
  },

})
const showCartActivityColor = computed(() => !cartShippingTop.value && getters['isShowCartActivityColor'] && !props.isInModal)

const freeShippingStrategy = computed(() => props.shippingInfo?.freeShippingStrategy || {})

const strategyPkData = computed(() => freeShippingStrategy.value?.strategyPkData || {})

const freeShippinpTips = computed(() => {
  return strategyPkData.value?.tip || ''
})

const showShipSupplement = computed(() => {
  return strategyPkData.value?.isAddItem || false
})

const countDownType = computed(() => {
  return showCartActivityColor.value ? 'black' : props.floatCountDown ? 'green' : 'white'
})

const cartShippingTop = computed(() => getters['cartShippingTop'])

const analysisEvent = (id) => {
  let shipping_method = strategyPkData.value?.shippingList?.map(item => item.shipping_method_name)?.join(',') || props.shippingInfo?.shipping_method_name || ''
  let shipping_free_type = ''

  if (freeShippingStrategy.value.type === 'coupon') {
    shipping_free_type = strategyPkData.value?.shippingFreeType
  } else {
    shipping_free_type = props.mallCode == 'platform' ? 0 : 3
  }
  return {
    id: id,
    data: {
      type: freeShippingStrategy.value.type,
      is_fullshippingactivity: showShipSupplement.value ? 0 : 1,
      mall_code: props.mallCode == 'platform' ? '' : props.mallCode,
      origin_price: strategyPkData.value?.originPrice?.amountWithSymbol || '-',
      shipping_method,
      show_position: props.showCartRights ? 4 : (cartShippingTop.value ? 1 : (props.floatCountDown ? 2 : 3)),
      shipping_free_type,
      show_way: props.showWay,
    }
  }
}

const clickCartTip = () => {
  if (!showShipSupplement.value) return

  daEventCenter.triggerNotice(analysisEvent('1-8-5-4'))
  emit('addOn', {
    type: freeShippingStrategy.value?.type,
    coupon: strategyPkData.value?.coupon,
    popupInfoId: props.shippingInfo?.popupInfoId,
  })
}

</script>
<style lang="less" scoped>
.free-color {
  background-color: @sui_color_safety_bg;
}
.free-ship {
  padding: 16/75rem 24/75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--shipping-info-background, @sui_color_safety_bg);
  &_activity {
    border-radius: 12/37.5rem 12/37.5rem 0 0;
    background: @sui_color_safety;
    color: @sui_color_white;
    .cart-tip__operation {
      color: @sui_color_white;
    }
  }
  .item-icon {
    margin-right: 0.21rem;
  }
  .ship-content{
    flex: 1;
    text-align: left;
    margin-right: .32rem;
    color: #222;
    font-size: 12px;
    overflow: hidden;
    &_activity {
      color: @sui_color_white;
    }
    .tips {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
    }
    .float-count-down {
      position: absolute;
      right: 0rem;
      top: -0.34rem;
    }
  }
  .cart-tip__operation {
    text-decoration: none;
    font-weight: bold;
    color: #222;
    white-space: nowrap;
    .font-dpr(24px);
    .margin-l(0.32rem);
  }
  .icon-tip-wrap{
    display: flex;
    align-items: center;
  }
  &.old-rights-funs {
    .icon-tip-wrap {
      display: inline;
      overflow: hidden;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
      line-height: 34/75rem;
      .ship-content {
        display: inline;
        .tips {
          display: inline;
        }
      }
    }
    .ship-content {
      margin-right: 0;
    }
    .cart-tip__operation {
      margin-left: 8/37.5rem;
    }
  }
}

.cart-tip__operation_new {
  max-width: 140/75rem;
  display: flex;
  align-items: center;
}
.free-shipping-button {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
