var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.freeShippinpTips)?_c('div',{staticClass:"free-color"},[_c('div',{directives:[{name:"expose",rawName:"v-expose",value:(_setup.analysisEvent('1-8-5-3')),expression:"analysisEvent('1-8-5-3')"}],class:[
      'free-ship',
      _vm.showCartRights ? 'old-rights-funs' : '',
      {'free-ship_activity': _setup.showCartActivityColor}
    ],on:{"click":_setup.clickCartTip}},[_c('div',{staticClass:"icon-tip-wrap"},[_vm._t("default",function(){return [_c(!_setup.showShipSupplement ? _setup.sui_icon_selected_16px: _vm.showCartRights ? _setup.sui_icon_freeshipping_16px_color : _setup.sui_icon_shipping_16px,{tag:"component",staticClass:"item-icon",attrs:{"size":"16px","color":_vm.showCartRights ? '#0E8C33' : '#222'}})]}),_vm._v(" "),(_setup.freeShippinpTips)?_c('div',{staticClass:"ship-content",class:{'ship-content_activity': _setup.showCartActivityColor}},[_c('div',{staticClass:"tips",domProps:{"innerHTML":_vm._s(_setup.freeShippinpTips)}}),_vm._v(" "),(_vm.shippingInfo.showCountDown && _vm.shippingInfo.countDownTime > 0)?_c('div',{class:{'float-count-down': _vm.floatCountDown}},[_c(_setup.ClientOnly,[_c(_setup.CountDown,{attrs:{"is-left-time":true,"time-stamp":_vm.shippingInfo.countDownTime,"type":_setup.countDownType}})],1)],1):_vm._e()]):_vm._e()],2),_vm._v(" "),(_setup.showShipSupplement)?_c('a',{class:[
        'cart-tip__operation',
        _vm.showCartRights ? 'cart-tip__operation_new' : ''
      ],attrs:{"tabindex":"0","aria-label":_vm.language.SHEIN_KEY_PWA_15091}},[_c('span',{class:{ 'free-shipping-button': _vm.showCartRights }},[_vm._v("\n        "+_vm._s(_vm.language.SHEIN_KEY_PWA_15091)+"\n      ")]),_vm._v(" "),_c(_setup.sui_icon_more_right_12px,{attrs:{"size":"12px","is-rotate":_vm.cssRight}})],1):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }