<template>
  <div
    class="j-cart-header"
    :style="{
      '--background': `${titleNavStyleFromCcc?.bgImage?.src ? `url('${titleNavStyleFromCcc?.bgImage?.src}')` : ''}`,
      '--color': titleNavStyleFromCcc?.fontColor || '',
    }"
  >
    <s-title-nav
      class="j-cart-nav cart-nav_ccc"
    >
      <template slot="preblock">
        <div
          class="new-cart-nav"
        >
          <div
            class="new-cart-nav__left"
            @click="batchActive ? '' : router.back()"
          >
            <sui_icon_nav_back_24px
              v-show="!batchActive"
              class="nav-icon"
              size="24px"
              :is-rotate="locals.GB_cssRight"
            />
            <span class="new-cart-nav__title">{{ language.SHEIN_KEY_PWA_14965 }}</span>
            <span
              v-if="cartSum > 0"
              class="new-cart-nav__sum"
            >({{ cartSum }})</span>
          </div>
          <div class="newcomer-guide__address">
            <client-only>
              <CartAddress />
            </client-only>
          </div>
        </div>
      </template>
      <template slot="endblock">
        <div
          v-show="!batchActive"
          class="cart-header-right"
        >
          <i
            v-if="showShare && cartShareStyle === 'off'"
            v-expose="{id: '1-8-1-37', data: { type: '-', sense: '-' }}"
            class="cart-header__icon-right j-share-guide-icon"
            @click="goShare({ type: '-', cartShareStyle, sense: '-' })"
          >
            <sui_icon_share_24px size="24px" />
          </i>
          <ShareSocialSwiper
            v-show="showShare && cartShareStyle !== 'off'"
            ref="shareSocialSwiper"
            cache-key="ShareSocialSwiperSlideCartPage"
            :cart-share-style="cartShareStyle"
            class="cart-header__icon-right"
            @click.native="goShare({ type: cartShareStyle, sense: '-' })"
          />
          <a
            v-show="allProductLineSumQuantity > 1"
            ref="batchBtnRef"
            slot="reference"
            
            class="cart-header__icon-right"
            tabindex="0"
            :aria-label="language.SHEIN_KEY_PWA_17956"
            role="button"
            da-event-expose="1-8-7-6"
            da-event-click="1-8-7-9"
            @click="handleClickBatchEdit(0)"
          >
            <sui_icon_nav_select_24px size="24px" />
          </a>
          <a
            v-if="loginStatus"
            
            class="cart-header__icon-right j-wishlist-btn"
            href="javascript:void(0);"
            da-event-click="1-8-1-4"
            tabindex="0"
            :aria-label="language.SHEIN_KEY_PWA_15552"
            @click="handleClickWishlist()"
          >
            <sui_icon_nav_save_24px size="24px" />
          </a>
        </div>
        <div
          v-if="batchActive"
          class="cart-header-right"
        >
          <i
            ref="doneBtnRef"
            
            class="cart-header__right-done"
            href="javascript:void(0);"
            role="button"
            tabindex="0"
            :aria-label="language.SHEIN_KEY_PWA_15042"
            da-event-click="1-8-7-8"
            @click="handleClickBatchEdit(1)"
          >
            {{ language.SHEIN_KEY_PWA_15042 }}
          </i>
        </div>
      </template>
    </s-title-nav>
  </div>
</template>
<script>
export default {
  name: 'CartHeader',
}
</script>
<script setup>
import { ref, computed, watch, nextTick } from 'vue'
import { useStore } from '../../hooks/useStore'
import { useRouter } from '../../hooks/useRouter'
import { daEventCenter } from 'public/src/services/eventCenter'
import { markPoint } from 'public/src/services/mark/index.js'
import { sui_icon_share_24px, sui_icon_nav_back_24px, sui_icon_nav_select_24px, sui_icon_nav_save_24px } from '@shein-aidc/icon-vue2'
import ClientOnly from 'vue-client-only'

const ShareSocialSwiper = () => import(/* webpackChunkName: "cartv2-share-social-swiper" */'./../../components/cartShare/ShareSocialSwiper.vue')
const CartAddress = () => import(/* webpackChunkName: "cartv2-address" */'@/public/src/pages/cart_v2/components/cartAddress/index.vue')

const { state, commit, dispatch } = useStore()
const { routerGoWish, router } = useRouter()
let doneBtnRef = ref(null)
let batchBtnRef = ref(null)

const cartSum = computed(() => batchActive.value ? state.cartInfo.allProductLineSumQuantity : state.cartInfo.effectiveProductLineSumQuantity)
const titleNavStyleFromCcc = computed(() => state.titleNavStyleFromCcc)
const batchActive = computed(() => state.batchActive)
const language = computed(() => state.language)
const locals = computed(() => state.locals)
const loginStatus = computed(() => state.loginStatus)
const allProductLineSumQuantity = computed(() => state.cartInfo.allProductLineSumQuantity)
const showShare = computed(() => !batchActive.value && cartSum.value > 0)
const cartShareStyle = computed(() => state.cartAbtInfo?.['CartShare']?.param?.uxstyle ?? 'off')

watch(() => batchActive.value, (v) => {
  if (v) {
    daEventCenter.triggerNotice({ daId: '1-8-7-7' })
    nextTick(() => {
      doneBtnRef.value.focus()
    })
  } else {
    nextTick(() => {
      batchBtnRef.value.focus()
    })
  }
  // this.handleShareSwiperStatus(v)
})

const handleClickBatchEdit = async(v) => {
  if (v === 1) {
    setTimeout(() => {
      document.querySelector('.j-cart-list-filter')?.scrollTo({ left: 0 })
    }, 0)
  } else {
    commit('changeRootSLoadingStatus', {
      show: true,
      maskTransparent: true,
      type: 'curpage',
    })
    await dispatch('clearFilter')
    commit('changeRootSLoadingStatus', {
      show: false,
    })
  }
  commit('handleBatchActive', { checkSoldout: true })
}

const handleClickWishlist = () => {
  markPoint('toNextPageClick', 'public')
  routerGoWish({}, true)
}

const goShare = (extraData = {}) =>{
  daEventCenter.triggerNotice({
    daId: '1-8-1-38',
    extraData,
  })

  sessionStorage.removeItem('cartShareChecked') // 从购物车进入默认全部不勾选

  router.push({
    name: 'cartShareSelect',
  })
}
</script>
<style lang="less" scoped>
.cart-nav_ccc {
    background: var(--background, @sui_color_white);
    background-size: 100% auto;
    background-repeat: no-repeat;
    color: var(--color, @sui_color_gray_dark1);
  }
.cart-header-right {
  .flexbox();
  .align-center();
  text-align: right;
  white-space: nowrap;
  .cart-header__icon-right {
    margin-left: .32rem;
    touch-action: manipulation;
    display: inline-block;
    text-decoration: none;
    color: inherit;
    pointer-events: auto;
    line-height: 1;
    position: relative;
  }
}

.cart-header__right-done {
  font-size: 16px;
}

.newcomer-guide__address {
  width: 100%;
  text-align: center;
  .text-overflow();
}
.new-cart-nav {
  display: flex;
  align-items: center;
  max-width: 6.6rem;
  &__left{
    display: flex;
    align-items: center;
    margin-right: 8/75rem;
    .nav-icon{
      margin-right: 24/75rem;
    }
  }
  &__title{
    font-size: 17px;
    font-weight: bold;
    white-space: nowrap;
  }
  &__sum{
    font-size: 12px;
    padding: 0 0 0 4/75rem;
    font-weight: bold;
    white-space: nowrap;
  }
}
</style>
